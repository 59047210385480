.centre-dialog {
    align-items: center !important;
    height: 100%;
}

.full-height {
    height: 100%;
}

.indicator-list {
    margin-bottom: 40px;
}
.indicator-list .indicator-item {
    --min-height: 128px;
}
.indicator-list ion-thumbnail {
    width: 150px;
    height: 96px;
}

.afn-modal .modal-wrapper {
    --max-width: 93%;
    --max-height: 93%;
}

.afn-modal-content {
    --padding-start: 10px;
    --padding-end: 10px;
}

.afn-toolbar .right {
    text-align: right;
}
.afn-toolbar .center {
    text-align: center;
}
.afn-toolbar .title-small {
    font-size: 12px;
    margin-bottom: 7px;
}

.afn-toolbar .title-default {
    font-size: 1.1rem;
}

.afn-toolbar .header-buttons + .title-default {
    padding-right: 68px;
}

ion-modal.fullscreen {
    --width: 98%;
    --height: 98%;
}

.ion-padding-button {
    padding: 8px 16px;
}

.native-input:invalid {
    color: var(--ion-color-danger);
}
