.markdown blockquote {
    background-color: var(--ion-color-light-shade);
    border-left: 3px solid var(--ion-color-medium);
    padding: 0.5rem 1rem;
}

.markdown blockquote p:last-of-type {
    margin-bottom: 0;
}

.markdown table {
    border: 1px solid var(--ion-color-medium);
    border-collapse: collapse;
}
.markdown th {
    background-color: var(--ion-color-primary);
    color: white;
    font-weight: normal;
}

.markdown td,
.markdown th {
    border: 1px solid var(--ion-color-light-shade);
    padding: 0.5rem 0.75rem;
}
