.sitePhotoInfoModal dt {
    font-weight: 600;
    font-size: 0.9rem;
}

.sitePhotoInfoModal dd {
    padding: 0.5rem 0rem;
}

.sitePhotoInfoModal dd + dt {
    margin-top: 0.5rem;
}
